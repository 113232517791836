import qs from 'qs'
import { Variant } from '@folhomee/front-library'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { useState, useCallback, useEffect } from 'react'
import { get, reduce, omit, identity, size } from 'lodash'

import Table from '../../organisms/Table'
import Container from '../../atoms/Container'
import AdalertRow from '../../molecules/AdalertRow'
import SearchForm from '../../molecules/SearchForm'
import TableBottom from '../../molecules/TableBottom'
import DataContainer from '../../atoms/DataContainer'
import RightContainer from '../../atoms/RightContainer'
import { getMinMediaQuery } from '../../../utils/media'
import { SearchFieldsProps } from '../../molecules/SearchForm/SearchForm.types'
import { useFetchInfiniteAdAlertsQuery } from '../../../queries/AdAlerts'

const HEADER = [{
  label: 'COMMON.name',
  textLeft: true
}, {
  label: 'USER.user',
  textLeft: true
}, {
  label: 'COMMON.status',
  textLeft: true
}, {
  label: 'COMMON.actions',
  textLeft: true
}]

const BODY = [{
  key: 'name',
  title: 'name'
}, {
  key: 'user.first_name',
  title: 'user'
}, {
  key: 'status',
  title: 'status'
}, {
  key: 'actions',
  title: 'actions'
}]

const SOURCES_SEARCH_FIELDS = ['name']

const SEARCH_FIELDS = [{
  icon: 'astronaut' as Variant,
  title: 'COMMON.name',
  fields: [{
    key: 'name',
    placeholder: 'COMMON.name'
  }]
}]

const Adalerts = (): JSX.Element => {
  const [searchFields] = useState<SearchFieldsProps[]>(SEARCH_FIELDS)
  const [search, updateSearch] = useState<any>({})
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const [searchBuild, updateSearchBuild] = useState(true)
  const [searchParams, updateSearchParams] = useSearchParams()
  const { data, isFetching, fetchNextPage } = useFetchInfiniteAdAlertsQuery(search)

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr 1fr 1fr'
  }

  useEffect(() => {
    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [])

  const onSubmit = useCallback(async (values) => {
  }, [])

  const { values, handleSubmit } = useFormik({
    initialValues: {
    },
    onSubmit: onSubmit
  })

  useEffect(() => {
    handleSubmit()
  }, [values])

  useEffect(() => {
    const querySearch = qs.parse(searchParams.toString())

    updateSearch(reduce(omit(querySearch, ['page']), (acc, value, key) => ({
      ...acc,
      [key]: value
    }), {}))
    updateSearchBuild(false)
  }, [searchParams])

  const alerts = reduce(get(data, 'pages', []), (acc: any[], page: any): any[] => [...acc, ...get(page, 'data', [])], [])

  return (
    <Container>
      <SearchForm
        build={searchBuild}
        search={search}
        fields={searchFields}
        sources={alerts}
        visible={true}
        searchFields={SOURCES_SEARCH_FIELDS}
        formatSearch={identity}
        updateSearch={updateSearchParams}
        updateShowSearch={() => { }}
        updateSearchBuild={updateSearchBuild} />
      <RightContainer>
        <DataContainer>
          <Table
            row={AdalertRow}
            data={alerts}
            body={BODY}
            header={HEADER}
            options={options}
            bigScreen={bigScreen} />
          {size(alerts) > 20 && <TableBottom
            loading={isFetching}
            fetchNextPage={fetchNextPage} />}
        </DataContainer>
      </RightContainer>
    </Container>
  )
}

export default Adalerts
