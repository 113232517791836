import styled from 'styled-components'
import { get, map, isEqual } from 'lodash'
import { useState, useCallback } from 'react'

import Row from '../../atoms/Row'
import Data from '../Data'
import Text from '../../atoms/Text'
import Field from '../../atoms/Field'
import Modal from '../../organisms/Modal'
import ArticleForm from '../../organisms/ArticleForm'
import DisplayImage from '../../molecules/DisplayImage'
import ContainerRow from '../../atoms/ContainerRow'
import { ArticleRowProps } from './ArticleRow.types'
import { ButtonContainerProps } from '../AnnonceRow/ProgramRow.types'
import { useDeleteArticleQuery } from '../../../queries/Articles'

const ButtonContainer = styled.div<ButtonContainerProps>`
  border: 1px solid white;
  background-color: ${({ theme, status }) => get(theme, status)};
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
`
const ScrollContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 400px;
  height: 400px;
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ImagePreview = styled.div`
  position: relative;
  width: 7.6em;
  height: 5.5em;
  margin: 0;
  
  & > img {
    border-radius: 5px;
  }
`

const ArticleRow = ({ data, fields, options }: ArticleRowProps): JSX.Element => {
  const articleId: string = get(data, 'id', 0).toString()
  const [open, setOpen] = useState(false)
  const [delet, setDelet] = useState(false)

  const { mutateAsync: deleteAsync } = useDeleteArticleQuery(articleId)

  const handleOpenModal = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  const handleDeleteModal = useCallback(() => {
    setDelet(!delet)
  }, [delet, setDelet])

  const deleteArticle = useCallback(async () => {
    await deleteAsync({})
    setDelet(false)
  }, [articleId, setDelet])

  return (
    <ContainerRow options={options}>
      <Row options={options}>
        {map(fields, ({ key }) => {
          if (isEqual(key, 'image')) {
            return (
              <Data key={key} left column noPadding>
                <ImagePreview>
                  <DisplayImage
                    data={get(data, 'image', '')}
                    isFetching={false} />
                </ImagePreview>
              </Data>
            )
          }

          if (isEqual(key, 'link')) {
            return (
              <a
                rel='noreferrer'
                target='_blank'
                href={`https://www.trouvermonlocalpro.fr/articles/${articleId}/?name=${get(data, 'title', '') as string}`}>
                lien
              </a>
            )
          }

          if (isEqual(key, 'actions')) {
            return (
              <Inline key={key}>
                <ButtonContainer
                  status='orange'
                  onClick={handleOpenModal} >
                  <Text color='white'>
                    Modifier
                  </Text>
                </ButtonContainer>
                <ButtonContainer
                  status='grey'
                  onClick={handleDeleteModal}>
                  <Text color='white'>
                    X
                  </Text>
                </ButtonContainer>
              </Inline>
            )
          }

          return (
            <Data key={key}>
              <Field
                key={key}
                name={key}
                value={get(data, key)} />
            </Data>
          )
        })}
      </Row>
      <Modal isOpen={open} onRequestClose={handleOpenModal}>
        <ScrollContainer>
          <ArticleForm
            article={data}
            updateOpen={handleOpenModal} />
        </ScrollContainer>
      </Modal>
      <Modal isOpen={delet} onRequestClose={handleDeleteModal}>
        <>
          <Text>
            Êtes vous sûr de vouloir supprimer l&apos;article {get(data, 'title', '')} ?
          </Text>
          <Inline>
            <ButtonContainer status='orange' onClick={handleDeleteModal}>
              <Text color='white'>
                Annuler
              </Text>
            </ButtonContainer>
            <ButtonContainer status='green' onClick={deleteArticle}>
              <Text color='white'>
                Supprimer
              </Text>
            </ButtonContainer>
          </Inline>
        </>
      </Modal>
    </ContainerRow >
  )
}

export default ArticleRow
