import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'

import Page from '../../atoms/Page'
import AnnonceDetails from '../../organisms/AnnonceDetails'
import { useTabTitle } from '../../../hooks/useTabTitle'
import { useDecorateParams } from '../../../hooks/useDecorateParams'
import { useFetchAnnonceQuery } from '../../../queries/Annonces'

const Container = styled.div`
`

const Annonce = (): JSX.Element => {
  const params = useParams()

  const { data } = useFetchAnnonceQuery(get(params, 'id', ''), {})

  const ad = get(data, 'attributes')
  const adName = get(ad, 'name', '')

  useTabTitle(adName)
  useDecorateParams(adName)

  return (
    <Page>
      <Container>
        <AnnonceDetails ad={{ ...ad, id: get(params, 'id') }} />
      </Container>
    </Page>
  )
}

export default Annonce
