import { Suspense } from 'react'
import { get, isEqual } from 'lodash'
import { useSetRecoilState } from 'recoil'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider, QueryClient } from 'react-query'

import Router from './Routes'
import Loading from './components/organisms/Loading'
import { userTokenState } from './recoil/atoms'

const App = (): JSX.Element => {
  const setUserToken = useSetRecoilState(userTokenState)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        onError: error => {
          if (isEqual(get(error, 'response.status', ''), 401)) {
            setUserToken('')
          }
        }
      }
    }
  })

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Helmet>
          <title>Impulse TMLP</title>
          <meta name='description' content='Outil pour les sales pour vous permettre de mieux gérer vos prospects' />
        </Helmet>
      </HelmetProvider>
      <Suspense fallback={<Loading />}>
        <Router />
      </Suspense>
    </QueryClientProvider>
  )
}

export default App
