import styled from 'styled-components'
import { useState, useCallback } from 'react'
import { media, spacings } from '@folhomee/front-library'
import { get, map, isUndefined, isNull } from 'lodash'

import Map from '../../molecules/Map'
import Card from '../../atoms/Card'
import Text from '../../atoms/Text'
import Modal from '../Modal'
import FlexBox from '../../atoms/FlexBox'
import Carousel from '../Carousel'
import MaxContent from '../../atoms/MaxContent'
import AnnonceForm from '../AnnonceForm'
import AnnonceSummary from '../../molecules/AnnonceSummary'
import ProgramDescription from '../../molecules/ProgramDescription'
import { useSendAlertsQuery } from '../../../queries/Annonces'
import { ProgramDetailsProps } from './ProgramDetails.types'

const Container = styled(MaxContent)`
  gap: ${get(spacings, 'xs')}px;
  width: 100%;
  margin: ${get(spacings, 'xl')}px auto;
  display: flex;
  max-width: 1300px;

  ${media.lessThan('lg')`
    max-width: 100%;
    flex-direction: column;
  `}
`

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
const First = styled(FlexBox)`
  width: 60%;
`

const UpdateButton = styled.div`
  border: 1px solid white;
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => get(theme, 'orange')};
  cursor: pointer;
`

const ScrollContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 400px;
  height: 400px;
`

const AnnonceDetails = ({ ad, ...props }: ProgramDetailsProps): JSX.Element => {
  const [openUpdate, updateOpenUpdate] = useState<boolean>(false)
  const [openAlerts, setOpenAlerts] = useState<boolean>(false)
  const { mutateAsync } = useSendAlertsQuery(get(ad, 'id'))

  const handleOpenUpdateForm = useCallback(() => {
    updateOpenUpdate(!openUpdate)
  }, [openUpdate])

  const handleOpenAlertsForm = useCallback(() => {
    setOpenAlerts(!openAlerts)
  }, [openAlerts])

  const sendAlerts = useCallback(async () => {
    try {
      await mutateAsync({})
      setOpenAlerts(!openAlerts)
    } catch (error) {
    }
  }, [openAlerts])

  if (isUndefined(ad)) {
    return (
      <></>
    )
  }

  return (
    <Container {...props}>
      <First gap={16} >
        <Inline>
          <UpdateButton onClick={handleOpenUpdateForm}>
            <Text color='white' fontSize={20}>Modifier l&apos;annonce</Text>
          </UpdateButton>
          <UpdateButton onClick={handleOpenAlertsForm}>
            <Text color='white' fontSize={20}>Envoyer des alertes</Text>
          </UpdateButton>
        </Inline>
        <Carousel images={map(get(ad, 'images'), img => get(img, 'url', ''))} />
        <ProgramDescription program={ad} />
        {(!isNull(get(ad, 'lat')) && !isNull(get(ad, 'lat'))) && <Map position={{ lat: parseFloat(get(ad, 'lat', 0).toString()), lng: parseFloat(get(ad, 'lng', 0).toString()) }} />}
      </First>
      <FlexBox gap={16} width100 flexGrow>
        <FlexBox row gap={16} alignStart>
          <Card>
            <AnnonceSummary ad={ad} />
          </Card>
        </FlexBox>
      </FlexBox>
      <Modal isOpen={openUpdate} onRequestClose={handleOpenUpdateForm}>
        <ScrollContainer>
          <AnnonceForm
            ad={ad}
            updateOpen={updateOpenUpdate} />
        </ScrollContainer>
      </Modal>
      <Modal isOpen={openAlerts} onRequestClose={handleOpenAlertsForm}>
        <>
          <p>Envoyer des alertes pour cette annonce ?</p>
          <Inline>
            <UpdateButton onClick={handleOpenAlertsForm}>
              <Text color='white' fontSize={20}>Annuler</Text>
            </UpdateButton>
            <UpdateButton onClick={sendAlerts}>
              <Text color='white' fontSize={20}>Envoyer des alertes</Text>
            </UpdateButton>
          </Inline>
        </>
      </Modal>
    </Container>
  )
}

export default AnnonceDetails
