import qs from 'qs'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { get, reduce, omit, size } from 'lodash'
import { useState, useCallback, useEffect } from 'react'

import Modal from '../../organisms/Modal'
import Table from '../../organisms/Table'
import Container from '../../atoms/Container'
import ArticleRow from '../../molecules/ArticleRow'
import ArticleForm from '../../organisms/ArticleForm'
import TableBottom from '../../molecules/TableBottom'
import DataContainer from '../../atoms/DataContainer'
import RightContainer from '../../atoms/RightContainer'
import { getMinMediaQuery } from '../../../utils/media'
import { useFetchInfiniteArticlesQuery } from '../../../queries/Articles'

const HEADER = [{
  label: 'COMMON.image',
  textLeft: false
}, {
  label: 'ARTICLES.title',
  textLeft: false
}, {
  label: 'ARTICLES.subtitle',
  textLeft: false
}, {
  label: 'COMMON.createdDate',
  textLeft: false
}, {
  label: 'ARTICLES.externalLink',
  textLeft: false
}, {
  label: 'COMMON.actions',
  textLeft: false
}]

const BODY = [{
  key: 'image',
  title: 'image'
}, {
  key: 'title',
  title: 'title'
}, {
  key: 'subtitle',
  title: 'subtitle'
}, {
  key: 'created_at',
  title: 'created_at'
}, {
  key: 'link',
  title: 'link'
}, {
  key: 'actions',
  title: 'actions'
}]

const Button = styled.div`
  cursor: pointer;
  padding: 8px;
  background-color: ${({ theme }) => get(theme, 'blue', '#fff')};
  color: white;
  width: fit-content;
  border-radius: 24px;
  margin-bottom: 4px;
`

const ScrollContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 400px;
  height: 400px;
`

const Articles = (): JSX.Element => {
  const [search, updateSearch] = useState<any>({})
  const [open, setOpen] = useState<boolean>(false)
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const [searchParams] = useSearchParams()
  const { data, isFetching, fetchNextPage } = useFetchInfiniteArticlesQuery(search)

  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '0.3fr 1fr 1fr 1fr 0.5fr 0.5fr'
  }

  const handleOpenModal = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  useEffect(() => {
    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [])

  const onSubmit = useCallback(async (values) => {
  }, [])

  const { values, handleSubmit } = useFormik({
    initialValues: {
    },
    onSubmit: onSubmit
  })

  useEffect(() => {
    handleSubmit()
  }, [values])

  useEffect(() => {
    const querySearch = qs.parse(searchParams.toString())

    updateSearch(reduce(omit(querySearch, ['page']), (acc, value, key) => ({
      ...acc,
      [key]: value
    }), {}))
  }, [searchParams])

  const articles = reduce(get(data, 'pages', []), (acc: any[], page: any): any[] => [...acc, ...get(page, 'data', [])], [])

  return (
    <Container>
      <RightContainer>
        <Button onClick={handleOpenModal}>Créer un article</Button>
        <DataContainer>
          <Table
            row={ArticleRow}
            data={articles}
            body={BODY}
            header={HEADER}
            options={options}
            bigScreen={bigScreen} />
          {size(articles) > 20 && <TableBottom
            loading={isFetching}
            fetchNextPage={fetchNextPage} />}
        </DataContainer>
      </RightContainer>
      <Modal isOpen={open} onRequestClose={handleOpenModal}>
        <ScrollContainer>
          <ArticleForm
            article={{}}
            updateOpen={handleOpenModal} />
        </ScrollContainer>
      </Modal>
    </Container>
  )
}

export default Articles
