/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import styled from 'styled-components'
import { Button } from '@folhomee/front-library'
import { useFormik } from 'formik'
import { useState, useCallback, useRef } from 'react'
import { get, map, isEqual, isEmpty, nth, first, omit, forEach } from 'lodash'

import Text from '../../atoms/Text'
import { InputItem } from '../../templates/Form'
import { ArticleFormProps } from './ArticleForm.types'
import { useCreateArticleQuery, useMutateArticleQuery } from '../../../queries/Articles'

const fields = [{
  key: 'title',
  type: 'text',
  options: [],
  placeholder: 'ANNONCE.name'
}, {
  key: 'subtitle',
  type: 'text',
  options: [],
  placeholder: 'ARTICLES.subtitle'
}, {
  key: 'image',
  type: 'text',
  options: [],
  placeholder: 'COMMON.image'
}, {
  key: 'paragraphs',
  type: 'paragraphs',
  options: [],
  placeholder: 'ARTICLES.paragraphs'
}]

const Textarea = styled.textarea`
  width: 100%;
  height: 80px;
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
`

const ArticleForm = ({ article, updateOpen }: ArticleFormProps): JSX.Element => {
  const [error, updateError] = useState('')
  const [loading, setLoading] = useState(false)
  const { mutateAsync } = useMutateArticleQuery(get(article, 'id'))
  const { mutateAsync: createAsync } = useCreateArticleQuery()
  const imageInputRef = useRef<HTMLInputElement>(null)

  const onSubmit = useCallback(async (values) => {
    if (loading) {
      return
    }

    const formData = new FormData()
    const uploadedImage = get(values, 'upload', {})
    if (uploadedImage) {
      formData.append('article[image]', uploadedImage)
    }

    const valuesWithoutImages = omit(values, ['id', 'type', 'image', 'upload', 'paragraphs'])

    Object.keys(valuesWithoutImages).forEach(key => {
      formData.append(`article[${key}]`, valuesWithoutImages[key])
    })

    forEach(get(values, 'paragraphs', []), (paragraph, index) => {
      Object.keys(paragraph).forEach(key => {
        if (isEqual(key, 'type') || isEqual(key, 'id')) {
          return
        }
        formData.append(`article[paragraphs][${index}][${key}]`, paragraph[key])
      })
    })

    try {
      if (isEmpty(get(article, 'id', ''))) {
        await createAsync(formData)
      } else {
        await mutateAsync(formData)
      }
      updateOpen(false)
    } catch (error) {
      console.log('error ', error)
      updateError('Une erreur est survenue durant la création/modification des données')
    } finally {
      setLoading(false)
    }
  }, [loading])

  const { values, handleChange, setFieldTouched, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      title: get(article, 'title', ''),
      image: get(article, 'image', ''),
      upload: '',
      subtitle: get(article, 'subtitle', ''),
      paragraphs: get(article, 'paragraphs', [
        { title: '', content1: '', subtitle1: '', content2: '', subtitle2: '', text_link: '', link: '' },
        { title: '', content1: '', subtitle1: '', content2: '', subtitle2: '', text_link: '', link: '' },
        { title: '', content1: '', subtitle1: '', content2: '', subtitle2: '', text_link: '', link: '' }
      ])
    },
    onSubmit
  })

  const handleAddImage = useCallback(() => {
    if (imageInputRef.current != null) {
      imageInputRef.current.click()
    }
  }, [values])

  const handleImageChange = useCallback(async (event) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!(event.target.files)) {
      return
    }

    setFieldValue('upload', first(event.target.files))
  }, [setFieldValue])

  return (
    <div>
      {map(fields, ({ key, placeholder, type, options }, index) => {
        if (isEqual(key, 'image')) {
          return (
            <div>
              <Inline>
                <Text fontSize={20} strong={true}>IMAGE</Text>
                <button onClick={handleAddImage}>ajouter</button>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  style={{ display: 'none' }}
                  ref={imageInputRef}
                  onChange={handleImageChange}
                />
              </Inline>
              <div>
                {!isEmpty(get(values, 'upload.name')) && <Text>uploadé: {get(values, 'upload.name')}</Text>}
                <img src={get(values, 'image', '')} />
              </div>
            </div>
          )
        }

        if (isEqual(key, 'paragraphs')) {
          return map(get(values, key), (paragraph, idx) => {
            const key = `paragraphs[${idx}]`

            return (
              <div key={`paragraph-${idx}`}>
                <Text textType='subtitle'>Paragraphe {parseInt(idx) + 1}</Text>
                <Text>Titre</Text>
                <Textarea
                  name={`${key}.title`}
                  value={get(nth(get(values, 'paragraphs'), parseInt(idx)), 'title')}
                  onChange={(e) => {
                    setFieldValue(`${key}.title`, e.target.value)
                  }} />
                <Text>Sous-titre 1</Text>
                <Textarea
                  name={`${key}.subtitle1`}
                  value={get(nth(get(values, 'paragraphs'), parseInt(idx)), 'subtitle1')}
                  onChange={(e) => {
                    setFieldValue(`${key}.subtitle1`, e.target.value)
                  }} />
                <Text>Contenu 1</Text>
                <Textarea
                  name={`${key}.content1`}
                  value={get(nth(get(values, 'paragraphs'), parseInt(idx)), 'content1')}
                  onChange={(e) => {
                    setFieldValue(`${key}.content1`, e.target.value)
                  }} />
                <Text>Sous-titre 2</Text>
                <Textarea
                  name={`${key}.subtitle2`}
                  value={get(nth(get(values, 'paragraphs'), parseInt(idx)), 'subtitle2')}
                  onChange={(e) => {
                    setFieldValue(`${key}.subtitle2`, e.target.value)
                  }} />
                <Text>Contenu 2</Text>
                <Textarea
                  name={`${key}.content2`}
                  value={get(nth(get(values, 'paragraphs'), parseInt(idx)), 'content2')}
                  onChange={(e) => {
                    setFieldValue(`${key}.content2`, e.target.value)
                  }} />
                <Text>Texte du bouton</Text>
                <Textarea
                  name={`${key}.text_link`}
                  value={get(nth(get(values, 'paragraphs'), parseInt(idx)), 'text_link')}
                  onChange={(e) => {
                    setFieldValue(`${key}.text_link`, e.target.value)
                  }} />
                <Text>URL bouton</Text>
                <Textarea
                  name={`${key}.link`}
                  value={get(nth(get(values, 'paragraphs'), parseInt(idx)), 'link')}
                  onChange={(e) => {
                    setFieldValue(`${key}.link`, e.target.value)
                  }} />
              </div>
            )
          })
        }

        return (
          <InputItem
            key={index}
            field={{ key, placeholder, type, options }}
            values={values}
            optionNull={isEqual(type, 'options')}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched} />
        )
      })}
      {!isEmpty(error) && <Text color='danger'>{error}</Text>}
      <Button
        label={loading ? 'En attente...' : 'Valider'}
        onClick={async () => await onSubmit(values)}
        variant='success'
        importance='regular' />
    </div >
  )
}

export default ArticleForm
