import qs from 'qs'
import styled from 'styled-components'
import useAsyncEffect from 'use-async-effect'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { spacings, media } from '@folhomee/front-library'
import { useState, useEffect, useCallback } from 'react'
import { get, reduce, isEmpty, isEqual, omit, filter } from 'lodash'

import Text from '../../atoms/Text'
import Table from '../../organisms/Table'
import Container from '../../atoms/Container'
import AnnonceRow from '../../molecules/AnnonceRow'
import SearchForm from '../../molecules/SearchForm'
import LoadingDots from '../../atoms/LoadingDots'
import TableBottom from '../../molecules/TableBottom'
import RightContainer from '../../atoms/RightContainer'
import { getMinMediaQuery } from '../../../utils/media'
import { SearchFieldsProps } from '../../molecules/SearchForm/SearchForm.types'
import { useFetchSourcesQuery } from '../../../queries/Sources'
import { useFetchTaxationsQuery } from '../../../queries/Taxations'
import { DisplayProgramDataProps } from './Annonces.types'
import { BODY_ANNONCES, HEADER_ANNONCES } from '../../../utils/tables/annonces'
import { buildSimpleQuery, fetchOptions } from '../../../utils/search'
import { useFetchAnnoncesCountQuery, useFetchInfiniteAnnoncesQuery } from '../../../queries/Annonces'

const SEARCH_FIELDS = [{
  title: 'COMMON.id',
  fields: [{
    key: 'id',
    placeholder: 'COMMON.id'
  }]
}, {
  title: 'PROGRAM.location',
  borderTop: true,
  fields: [{
    key: 'location',
    type: 'location',
    subkeys: ['cities', 'departments', 'agglomerations'],
    multiple: true,
    placeholder: 'PROGRAM_SEARCH.locationPlaceholder'
  }]
}, {
  title: 'ANNONCE.title',
  fields: [{
    key: 'name',
    placeholder: 'ANNONCE.title'
  }]
}, {
  title: 'ANNONCE.user',
  fields: [{
    key: 'user',
    placeholder: 'ANNONCE.userPlaceholder'
  }]
}, {
  title: 'ANNONCE.payment',
  fields: [{
    type: 'toggle',
    key: 'payment',
    options: [{
      value: 'all',
      label: 'ANNONCE.hasPayed'
    }, {
      value: 'boost',
      label: 'ANNONCE.boost'
    }, {
      value: 'classic',
      label: 'ANNONCE.classic'
    }, {
      value: 'none',
      label: 'ANNONCE.hasNotPayed'
    }],
    placeholder: 'ANNONCE.payment'
  }]
}, {
  title: 'ANNONCE.adType',
  fields: [{
    type: 'toggle',
    key: 'adType',
    options: [{
      value: ['sale'],
      label: 'ANNONCE.sale'
    }, {
      value: ['rental'],
      label: 'ANNONCE.rental'
    }, {
      value: ['sublet'],
      label: 'ANNONCE.sublet'
    }],
    placeholder: 'ANNONCE.adType'
  }]
}, {
  title: 'ANNONCE.debug',
  fields: [{
    type: 'toggle',
    key: 'debug',
    options: [{
      value: 'city',
      label: 'ANNONCE.debugCity'
    }, {
      value: 'user',
      label: 'ANNONCE.debugUser'
    }],
    placeholder: 'ANNONCE.debug'
  }]
}, {
  title: 'ANNONCE.whole',
  fields: [{
    type: 'toggle',
    key: 'whole',
    options: [{
      value: true,
      label: 'ANNONCE.full'
    }, {
      value: false,
      label: 'ANNONCE.partial'
    }],
    placeholder: 'ANNONCE.whole'
  }]
}, {
  title: 'ANNONCE.availability',
  fields: [{
    type: 'toggle',
    key: 'availability',
    options: [{
      value: 'full-time',
      label: 'ANNONCE.full-time'
    }, {
      value: 'mid-time',
      label: 'ANNONCE.mid-time'
    }],
    placeholder: 'ANNONCE.availability'
  }]
}]

const StyledTable = styled(Table)`
  max-width: 100%;
`

const DataContainer = styled.div`
  margin-top: 16px;

  ${media.greaterThan('sm')`
    padding: 0 ${get(spacings, 's')}px ${get(spacings, 's')}px ${get(spacings, 's')}px;
  `}
`

const Inline = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
`

const DisplayAnnonceData = ({ isFetching, fetchNextPage, bigScreen, data }: DisplayProgramDataProps): JSX.Element => {
  const options = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1.5fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.9fr'
  }

  const optionsMobile = {
    bodyColor: 'extraLightBlue',
    headerColor: 'transparent',
    columnAlignment: '1fr 1fr 1fr 1fr 1fr 1fr 0.8fr 0.8fr'
  }

  return (
    <>
      <StyledTable
        row={AnnonceRow}
        data={data}
        body={isEqual(bigScreen, true) ? BODY_ANNONCES : BODY_ANNONCES}
        header={HEADER_ANNONCES}
        options={bigScreen ? options : optionsMobile}
        bigScreen={bigScreen}
        noPadding={true} />
      <TableBottom
        loading={isFetching}
        fetchNextPage={fetchNextPage} />
    </>
  )
}

const Annonces = (): JSX.Element => {
  const { t } = useTranslation()
  const [search, updateSearch] = useState<any>({})
  const [loading, updateLoading] = useState<boolean>(true)
  const [bigScreen, updatebigScreen] = useState<boolean>(getMinMediaQuery('xl').matches)
  const [searchBuild, updateSearchBuild] = useState(true)
  const [searchParams, updateSearchParams] = useSearchParams()
  const [searchFields, updateSearchFields] = useState<SearchFieldsProps[]>(SEARCH_FIELDS)
  const { data, isFetching, fetchNextPage } = useFetchInfiniteAnnoncesQuery(search)
  const { data: count, isFetching: isFetchingCount } = useFetchAnnoncesCountQuery(search)
  const { data: sources, isFetching: isFetchingSources } = useFetchSourcesQuery()
  const { data: taxations, isFetching: isFetchingTaxations } = useFetchTaxationsQuery()

  const formatProgramSearch = useCallback((search) => buildSimpleQuery(search), [])

  const addOptionsToFields = async (searchFields: SearchFieldsProps[]): Promise<void> => {
    const fields = await fetchOptions(searchFields, [...taxations ?? [], { label: t('PROGRAM_SEARCH.withoutTaxation'), value: -1 }], sources)

    updateSearchFields(fields)
    updateLoading(false)
  }

  useAsyncEffect(async () => {
    if ((!isFetchingTaxations && !isEmpty(taxations)) || (!isFetchingSources && !isEmpty(sources))) {
      await addOptionsToFields(searchFields)
    }

    const mql = getMinMediaQuery('xl')
    const resize = (event: MediaQueryListEvent): void => updatebigScreen(event.matches)

    mql.addEventListener('change', resize)
    return () => {
      mql.removeEventListener('change', resize)
    }
  }, [sources, taxations, isFetchingTaxations, isFetchingSources])

  useEffect(() => {
    const querySearch = qs.parse(searchParams.toString())

    updateSearch(reduce(omit(querySearch, ['page', 'alert_id']), (acc, value, key) => ({
      ...acc,
      [key]: value
    }), {}))
  }, [searchParams])

  if (isEqual(loading, true)) {
    return (
      <></>
    )
  }

  const displayData = filter(reduce(get(data, 'pages', []), (acc: any[], items: any[]): any[] =>
    [...acc, ...get(items, 'data', [])], []), item => !isEmpty(item))

  return (
    <Container>
      <SearchForm
        build={searchBuild}
        search={search}
        fields={searchFields}
        visible={true}
        searchFields={['id', 'name', 'user', 'whole', 'debug', 'adType', 'payment', 'availability', 'location']}
        formatSearch={formatProgramSearch}
        updateSearch={updateSearchParams}
        updateShowSearch={() => { }}
        updateSearchBuild={updateSearchBuild} />
      <RightContainer>
        <DataContainer>
          <Inline>
            <Text textType='big' strong>
              {!isFetchingCount && get(count, 'ads', 0)}
              {isFetchingCount && <LoadingDots />}
            </Text>
            <Text textType='big' strong>
              annonces
            </Text>
          </Inline>
          <DisplayAnnonceData
            data={displayData}
            search={search}
            showMap={false}
            bigScreen={bigScreen}
            isFetching={isFetching}
            fetchNextPage={fetchNextPage} />
        </DataContainer>
      </RightContainer>
    </Container >
  )
}

export default Annonces
