import { AxiosError } from 'axios'
// @ts-expect-error
import { deserialize } from 'deserialize-json-api'
import { get, reduce, isEmpty, map, omit, range, size } from 'lodash'
import { useQueryClient, InfiniteData, useInfiniteQuery, useMutation, UseMutationResult, UseInfiniteQueryResult } from 'react-query'

import useClient from '../../hooks/useClient'
import { buildQueryParams } from '../utils'

const transformListPage = ({ data }: any): any => {
  const page = deserialize(data)

  return {
    meta: get(data, 'meta'),
    data: reduce(get(page, 'data'), (acc: any, item: any): any => {
      if (!isEmpty(item)) {
        return [...acc, item]
      }
      return acc
    }, [])
  }
}

export const useFetchInfiniteArticlesQuery = (search: any): UseInfiniteQueryResult<any, AxiosError> => {
  const client = useClient()

  return useInfiniteQuery(['fetchInfiniteArticles', search], async ({ pageParam = 1 }) => {
    return await client.get('/api/articles', {
      params: {
        'page[number]': pageParam,
        'page[size]': 11,
        ...buildQueryParams(omit(search, ['page']))
      }
    })
  }, {
    select: ({ pages }): InfiniteData<any> => ({
      pages: map(pages, transformListPage),
      pageParams: range(size(pages) + 1)
    }),
    getNextPageParam: (_, allPages) => size(allPages) + 1,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false
  })
}

export const useCreateArticleQuery = (): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (values) => {
    await client.post('/api/articles', values)
  }, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchInfiniteArticles'])
    }
  })
}

export const useMutateArticleQuery = (articleId: string): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async (values) => {
    await client.patch(`/api/articles/${articleId}`, values)
  }, {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchInfiniteArticles'])
    }
  })
}

export const useDeleteArticleQuery = (articleId: string): UseMutationResult => {
  const client = useClient()
  const queryClient = useQueryClient()

  return useMutation(async () =>
    await client.delete(`/api/articles/${articleId}`), {
    onSuccess: async () => {
      await queryClient.refetchQueries(['fetchInfiniteArticles'])
    }
  })
}
