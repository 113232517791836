import qs from 'qs'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { useTranslation } from 'react-i18next'
import { media, spacings } from '@folhomee/front-library'
import { get, omit, isUndefined, isEqual, map, filter, isEmpty } from 'lodash'

import Icon from '../../atoms/Icon'
import Text from '../../atoms/Text'
import FlexBox from '../../atoms/FlexBox'
import useClient from '../../../hooks/useClient'
import SearchInput from '../../molecules/SearchInput'
import ButtonContainer from '../../atoms/ButtonContainer'
import { buildForm } from '../../../utils/search'
import { getMaxMediaQuery } from '../../../utils/media'
import {
  SearchFormProps, DisplayProps, SearchTitleProps,
  SearchFieldsProps, OptionIconProps, SearchFieldProps, InlineSearchProps,
  BorderTopProps
} from './SearchForm.types'

const Container = styled.div<DisplayProps>`
  width: 100%;
  height: 100vh;
  display: ${({ visible }) => visible ?? true ? 'flex' : 'none'};
  z-index: 1;
  position: absolute;
  max-width: auto;
  padding-top: ${({ visible }) => visible ?? true ? '40' : '16'}px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => get(theme, 'white')};
  
  ${media.greaterThan('lg')`
    width: 24%;
    position: relative;
    max-width: 320px;
    padding-top: ${get(spacings, 'xl')}px;
    box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  `}
`

const CriteriaContainer = styled.div`
  gap: ${get(spacings, 'xs')}px;
  height: auto;
  display: flex;
  padding:  ${get(spacings, 's')}px;
  overflow-y: auto;
  flex-direction: column;
  padding-bottom: 96px;
`

const BottomSearch = styled.div`
  left: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  padding: ${get(spacings, 'xs')}px 0;
  position: absolute;
  background: ${({ theme }) => get(theme, 'white')};
  box-shadow: 0 -3px 2px 0 rgba(51, 102, 204, 0.1);
  align-items: center;
  justify-content: center;
`

const LabelCriteria = styled.div`
  gap: 4px;
  display: flex;
  margin-bottom: ${get(spacings, 'xxs')}px;
`

const Inline = styled.div<InlineSearchProps>`
  gap: ${get(spacings, 'xxs')}px;
  width: 100%;
  display: ${({ type }) => isEqual(type, 'grid') ? 'grid' : 'flex'};
  align-items: ${({ type }) => isEqual(type, 'inlineTop') ? 'start' : 'center'};
  grid-template-columns: 1fr 1fr;

  & > div {
    gap: ${get(spacings, 'xxs')}px;
    width: 100%;
  }

  & p {
    ${media.lessThan('md')`
      font-size: 14px;
    `}
`

const Criteria = styled.div<BorderTopProps>`
  width: 100%;
  border-top: ${({ borderTop }) => isEqual(borderTop, true) ? '1px solid #707070' : 0};
  padding-top: ${({ borderTop }) => isEqual(borderTop, true) ? '12px' : 0};
`

const StyledIcon = styled(Icon) <OptionIconProps>`
  transform: scale(0.8);

  & path, rect, circle {
    stroke: ${({ theme }) => get(theme, 'darkGrey')} !important;
  }
`

const StyledFlexBox = styled(FlexBox)`
  ${media.greaterThan('xl')`
  display: none;
  `}
`

const SearchTitleMobile = ({ title, updateShowSearch }: SearchTitleProps): JSX.Element => (
  <StyledFlexBox
    justifyContent='space-between'
    row >
    <Text
      fontSize={20}
      color='primary'
      strong >
      <span>{title}</span>
    </Text>
    <Icon
      variant='close-circle'
      onClick={() => updateShowSearch(false)}
      cursor
      stroke />
  </StyledFlexBox>
)

const SearchForm = ({ search, fields, visible, build, formatSearch, updateSearch, updateShowSearch, updateSearchBuild, searchFields }: SearchFormProps): JSX.Element => {
  const { t } = useTranslation()
  const client = useClient()

  const onSubmit = useCallback((values) => {
    const search = formatSearch(values)
    const queryParams = new URLSearchParams(qs.stringify({
      ...search
    }))

    updateSearch(queryParams)

    if (getMaxMediaQuery('md').matches) {
      updateShowSearch(false)
    }
  }, [updateSearch, updateSearchBuild, updateShowSearch])

  const { handleChange, handleBlur, values, setFieldValue } = useFormik({
    initialValues: {},
    onSubmit: onSubmit
  })

  const onKeyPress = useCallback((evt) => {
    if (isEqual(get(evt, 'key'), 'Enter')) {
      return onSubmit(values)
    }
  }, [values])

  const setFieldValueHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<void> => {
    if (!isUndefined(add) && isEqual(add, false)) {
      await setFieldValue(field, '')
      return
    }

    await setFieldValue(field, value)
  }, [])

  const setFieldValuesHandler = useCallback(async (field: string, value: any, add?: boolean): Promise<any> => {
    const currValues = get(values, field, [])
    if (!isUndefined(add) && isEqual(add, false)) {
      return await setFieldValue(field, filter(currValues, elt => !isEqual(`${get(elt, 'value') as string}`, `${get(value, 'value') as string}`)))
    }

    await setFieldValue(field, [...currValues, value])
  }, [values])

  useAsyncEffect(async () => {
    if (!isEmpty(fields) && isEqual(build, false)) {
      updateSearchBuild(true)
      await buildForm(searchFields, client, omit(search, ['page']), setFieldValue, fields)
    }
  }, [search, fields, build, updateSearchBuild])

  return (
    <Container visible={visible} onKeyPress={onKeyPress}>
      <CriteriaContainer>
        <SearchTitleMobile title={t('COMMON.search')} updateShowSearch={updateShowSearch} />
        {map(fields, ({ type, icon, title, fields: innerFields, optionIcon, borderTop }: SearchFieldsProps) => (
          <Criteria key={title} borderTop={borderTop}>
            <LabelCriteria>
              {!isUndefined(icon) &&
                <StyledIcon
                  {...optionIcon}
                  variant={icon} />}
              <Text strong>
                {t<string>(title)}
              </Text>
            </LabelCriteria>
            <Inline type={type}>
              {map(innerFields, ({ key, type, options, multiple, placeholder }: SearchFieldProps) => (
                <SearchInput
                  key={key}
                  name={key}
                  type={type}
                  values={values}
                  options={options}
                  multiple={multiple}
                  handleBlur={handleBlur}
                  placeholder={placeholder}
                  handleChange={handleChange}
                  setFieldValue={isEqual(multiple, true) ? setFieldValuesHandler : setFieldValueHandler} />
              ))}
            </Inline>
          </Criteria>
        ))}
      </CriteriaContainer>
      <BottomSearch>
        <ButtonContainer
          label={t('COMMON.search')}
          color='danger'
          onClick={() => onSubmit(values)}
          buttonSize='large'
          minWidth={200} />
      </BottomSearch>
    </Container>
  )
}

export default SearchForm
