import axios from 'axios'
import styled from 'styled-components'
import imageCompression from 'browser-image-compression'
import { get, omit, map, filter, isEqual, split, isEmpty, first, reduce, isUndefined, forEach } from 'lodash'
import { Button } from '@folhomee/front-library'
import { useFormik } from 'formik'
import { useRef, useState, useCallback } from 'react'

import Text from '../../atoms/Text'
import { InputItem } from '../../templates/Form'
import { AnnonceFormProps } from './AnnonceForm.types'
import { useMutateAnnonceQuery } from '../../../queries/Annonces'

const Inline = styled.div`
  display: flex;
  flex-direction: row;
`
const GouvAddress = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
`
const Address = styled.div`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => get(theme, 'blue')}
  }
`
const Image = styled.img`
  width: 200px;
  height: 200px;
`
const Cross = styled.div`
  color: red;
  position: relative;
  top: 24px;
  left: 12px;
  cursor: pointer;
  font-weight: 600;
`
const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

const MAX_IMAGES = 5

const AnnonceForm = ({ ad, updateOpen }: AnnonceFormProps): JSX.Element => {
  const [gouvData, updategouvData] = useState({})
  const [error, updateError] = useState('')
  const [loading, setLoading] = useState(false)
  const [imageError, setImageError] = useState('')
  const imageInputRef = useRef<HTMLInputElement>(null)
  const { mutateAsync } = useMutateAnnonceQuery(get(ad, 'id'))

  const onSubmit = useCallback(async (values) => {
    if (loading) {
      return
    }

    setLoading(true)
    const formData = new FormData()
    const uploadedImages = get(values, 'uploaded_images', [])
    forEach(uploadedImages, file => {
      formData.append('ad[uploaded_images][]', file)
    })

    const removedImages = get(values, 'images_to_remove', [])
    forEach(removedImages, id => {
      formData.append('ad[images_to_remove][]', id)
    })

    const valuesWithoutImages = omit(values, ['images', 'images_to_remove', 'uploaded_images'])
    Object.keys(valuesWithoutImages).forEach(key => {
      formData.append(`ad[${key}]`, valuesWithoutImages[key])
    })

    try {
      await mutateAsync(formData)
      updateOpen(false)
    } catch (error) {
      console.log('error ', error)
      updateError('Une erreur est survenue durant la modification des données')
    } finally {
      setLoading(false)
    }
  }, [loading])

  const { values, handleChange, setFieldTouched, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      lat: get(ad, 'lat'),
      lng: get(ad, 'lng'),
      city: get(ad, 'display_city'),
      name: get(ad, 'name'),
      days: isEmpty(get(ad, 'days')) ? '' : split(get(ad, 'days'), ','),
      floor: get(ad, 'floor'),
      rooms: get(ad, 'rooms'),
      insee: '',
      price: get(ad, 'min_price'),
      whole: get(ad, 'whole'),
      ad_type: get(ad, 'adType'),
      images: get(ad, 'images'),
      address: get(ad, 'address'),
      zipcode: get(ad, 'zipcode'),
      surface: get(ad, 'surface'),
      amount: first(map(get(ad, 'payments'), item => get(item, 'amount'))),
      payments: first(map(get(ad, 'payments'), item => get(item, 'slug'))),
      subscription: first(map(get(ad, 'payments'), item => get(item, 'subscription'))),
      fulltime: isEmpty(get(ad, 'days')) ? 'true' : 'false',
      professions: isEmpty(get(ad, 'professions')) ? ['all'] : split(get(ad, 'professions'), ','),
      uploaded_images: [],
      images_to_remove: [],
      description: get(ad, 'description'),
      property_tax: get(ad, 'property_tax'),
      monthly_charge: get(ad, 'monthly_charge')
    },
    onSubmit
  })

  const searchAPIgouv = useCallback(async () => {
    const address = get(values, 'address') as string
    const city = get(values, 'city') as string
    const zipcode = get(values, 'zipcode') as string

    const queryParams: string[] = reduce<string, string[]>([address, city, zipcode], (acc, elt) => {
      return isUndefined(elt) || isEmpty(elt) ? acc : [...acc, elt]
    }, [])

    const queryString = queryParams.join(', ')
    const res = await axios.get('https://api-adresse.data.gouv.fr/search', {

      params: {
        q: queryString
      }
    })

    updategouvData(get(res, 'data.features'))
  }, [values])

  const handleAddressSelected = useCallback((elt: any) => {
    setFieldValue('city', get(elt, 'properties.city'))
    setFieldValue('zipcode', get(elt, 'properties.postcode'))
    setFieldValue('insee', get(elt, 'properties.citycode'))
    setFieldValue('address', get(elt, 'properties.name'))
    setFieldValue('lat', get(elt, 'geometry.coordinates')[1])
    setFieldValue('lng', get(elt, 'geometry.coordinates')[0])

    updategouvData({})
  }, [values])

  const handleAddImage = useCallback(() => {
    // if (imageInputRef.current != null) {
    //   imageInputRef.current.click()
    // }
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const currentNumberOfImages = get(values, 'uploaded_images', []).length + get(values, 'images', []).length
    if (currentNumberOfImages >= MAX_IMAGES) {
      setImageError(`Vous ne pouvez télécharger que ${MAX_IMAGES} images.`)
      return
    }
    if (imageInputRef.current != null) {
      imageInputRef.current.click()
    }
  }, [values])

  const handleImageChange = useCallback(async (event) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!(event.target.files)) {
      return
    }

    setLoading(true)

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const existingImagesCount = get(values, 'uploaded_images', []).length + get(values, 'images', []).length
    let files: File[] = Array.from(event.target.files)

    // Limitez le nombre de nouvelles images en fonction du nombre existant
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    if (existingImagesCount + files.length > MAX_IMAGES) {
      setImageError(`Vous pouvez télécharger seulement ${MAX_IMAGES - existingImagesCount} images supplémentaires.`)
      files = files.slice(0, MAX_IMAGES - existingImagesCount)
    }

    const compressedFiles = await Promise.all(files.map(async (file: File) => {
      const options = {
        maxSizeMB: 0.5, // La taille maximale en Mo
        maxWidthOrHeight: 1920, // Largeur/hauteur maximale
        useWebWorker: true // Active l'utilisation de web workers pour de meilleures performances
      }

      try {
        const compressedFile = await imageCompression(file, options)
        return compressedFile
      } catch (error) {
        return file
      } finally {
        setLoading(false)
      }
    }))

    setFieldValue('uploaded_images', compressedFiles)
  }, [values, setFieldValue])

  const handleDeleteImage = useCallback((id) => {
    const filtre = filter(get(values, 'images'), img => !isEqual(get(img, 'id'), id))
    setFieldValue('images', filtre)
    setFieldValue('images_to_remove', [...get(values, 'images_to_remove'), id])
  }, [values])

  const handleDeleteFile = useCallback((name) => {
    const filtre = filter(get(values, 'uploaded_images'), file => !isEqual(get(file, 'name'), name))
    setFieldValue('uploaded_images', filtre)
  }, [values])

  const fields = [{
    key: 'ad_type',
    type: 'options',
    options: [{
      value: 'sale',
      label: 'Vendre'
    }, {
      value: 'rental',
      label: 'Location'
    }],
    placeholder: 'ANNONCE.adType'
  }, {
    key: 'name',
    type: 'text',
    options: [],
    placeholder: 'ANNONCE.name'
  }, {
    key: 'payments',
    type: 'payments',
    options: [{
      value: 'classic',
      label: 'Classique'
    }, {
      value: 'boost',
      label: 'Boost'
    }, {
      value: 'premium',
      label: 'Premium'
    }],
    placeholder: 'ANNONCE.payments'
  }, {
    key: 'address',
    type: 'text',
    options: [],
    placeholder: 'ANNONCE.address'
  }, {
    key: 'price',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.price'
  }, {
    key: 'monthly_charge',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.monthlyCharge'
  }, {
    key: 'property_tax',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.propertyTax'
  }, {
    key: 'surface',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.surface'
  }, {
    key: 'whole',
    type: 'options',
    options: [{
      value: 'true',
      label: 'Local entier'
    }, {
      value: 'false',
      label: 'Salle privative'
    }],
    placeholder: 'ANNONCE.whole'
  }, {
    key: 'rooms',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.rooms'
  }, {
    key: 'floor',
    type: 'number',
    options: [],
    placeholder: 'ANNONCE.floor'
  }, {
    key: 'fulltime',
    type: 'options',
    options: [{
      value: 'true',
      label: 'Temps plein'
    }, {
      value: 'false',
      label: 'Temps partiel'
    }],
    placeholder: 'ANNONCE.fulltime'
  }, {
    key: 'days',
    type: 'multiselection',
    options: [{
      value: 'Lundi',
      label: 'Lundi'
    }, {
      value: 'Mardi',
      label: 'Mardi'
    }, {
      value: 'Mercredi',
      label: 'Mercredi'
    }, {
      value: 'Jeudi',
      label: 'Jeudi'
    }, {
      value: 'Vendredi',
      label: 'Vendredi'
    }, {
      value: 'Samedi',
      label: 'Samedi'
    }, {
      value: 'Dimanche',
      label: 'Dimanche'
    }],
    placeholder: 'ANNONCE.days'
  }, {
    key: 'professions',
    type: 'multiselection',
    options: [{
      value: 'all',
      label: 'Toutes catégories'
    }, {
      value: 'Médical',
      label: 'Médical'
    }, {
      value: 'Paramédical',
      label: 'Paramédical'
    }, {
      value: 'Juridique',
      label: 'Juridique'
    }, {
      value: 'Conseil/Finance',
      label: 'Conseil/Finance'
    }, {
      value: 'Autre',
      label: 'Autre'
    }],
    placeholder: 'ANNONCE.professions'
  }, {
    key: 'description',
    type: 'textarea',
    options: [],
    placeholder: 'ANNONCE.description'
  }, {
    key: 'images',
    type: 'text',
    options: [],
    placeholder: 'ANNONCE.images'
  }]

  return (
    <div>
      {map(fields, ({ key, placeholder, type, options }, index) => {
        if (isEqual(key, 'address')) {
          return (
            <>
              <Inline>
                <InputItem
                  key={`address-${index}`}
                  field={{ key, placeholder, type, options }}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched} />
                <InputItem
                  key={`city-${index}`}
                  field={{ key: 'city', placeholder: 'ANNONCE.city', type, options }}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched} />
                <InputItem
                  key={`zipcode-${index}`}
                  field={{ key: 'zipcode', placeholder: 'ANNONCE.zipcode', type, options }}
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched} />
                <button onClick={searchAPIgouv}>chercher sur l&apos;api du gouv</button>
              </Inline>
              {!isEmpty(gouvData) &&
                <GouvAddress>{map(gouvData, elt => {
                  return (
                    <div>
                      <Address onClick={() => handleAddressSelected(elt)}>{get(elt, 'properties.label')}</Address>
                    </div>
                  )
                })}
                </GouvAddress>}
            </>
          )
        }

        if (isEqual(key, 'images')) {
          return (
            <div>
              <Inline>
                <Text fontSize={20} strong={true}>IMAGES</Text>
                <button onClick={handleAddImage}>ajouter</button>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  style={{ display: 'none' }}
                  ref={imageInputRef}
                  onChange={handleImageChange}
                />
              </Inline>
              <Text>{imageError}</Text>
              <div>
                {map(get(values, 'uploaded_images'), elt => {
                  return (
                    <Inline>
                      <Text>{get(elt, 'name')}</Text>
                      <div onClick={() => handleDeleteFile(get(elt, 'name'))}>
                        <Text color='danger' >X</Text>
                      </div>
                    </Inline>
                  )
                })}
              </div>
              <Wrapper>
                {map(get(values, 'images'), img => {
                  return <div>
                    <Cross onClick={() => handleDeleteImage(get(img, 'id'))}>supprimer</Cross>
                    <Image src={get(img, 'url')} />
                  </div>
                })}
              </Wrapper>
            </div>
          )
        }

        if (isEqual(key, 'whole') && isEqual(get(values, 'ad_type'), 'sale')) {
          return null
        }

        if (isEqual(key, 'days') && isEqual(get(values, 'fulltime'), 'true')) {
          return null
        }

        return (
          <InputItem
            key={index}
            field={{ key, placeholder, type, options }}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched} />
        )
      })}
      {!isEmpty(error) && <Text color='danger'>{error}</Text>}
      <Button
        label={loading ? 'En attente...' : 'Valider'}
        onClick={async () => await onSubmit(values)}
        variant='success'
        importance='regular' />
    </div >
  )
}

export default AnnonceForm
