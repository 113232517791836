/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { Link, useLocation } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { media, spacings } from '@folhomee/front-library'
import { get, map, isEqual, filter, isNull } from 'lodash'

import Icon from '../../atoms/Icon'
import Logo from '../../atoms/Logo'
import Text from '../../atoms/Text'
import { userTokenState } from '../../../recoil/atoms'
import { useFetchMeQuery } from '../../../queries/Users'
import { LinkObject, LinksProps, MobileMenuProps, UserPartProps, LinkProps } from './Navbar.types'

const LINKS: LinkObject[] = [{
  link: '/annonces',
  icon: 'zoom',
  title: 'NAVBAR.annonces',
  admin: true
}, {
  link: '/adalerts',
  icon: 'alert',
  title: 'NAVBAR.adalerts',
  admin: true
}, {
  link: '/users',
  icon: 'list',
  title: 'NAVBAR.users',
  admin: true
}, {
  link: '/articles',
  icon: 'edit',
  title: 'ARTICLES.articles',
  admin: false
}]

const Container = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  z-index: 9999;
  padding: 8px 18px;
  position: fixed;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  align-items: center;
  flex-direction: row;
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};

  ${media.lessThan('md')`
    box-shadow: none;
    border-bottom: 1px solid #0049EE20;

    & > a {
      margin-left: -80px;
    }
  `}
`

const DesktopContent = styled.div`
  gap: 16px;
  flex: 1;
  display: flex;

  ${media.lessThan('md')`
    display: none;
  `}
`

const MobileContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end; 

  & > svg {
    cursor: pointer;
  }

  ${media.greaterThan('md')`
    display: none;
  `}
`

const MobileMenu = styled.div<MobileMenuProps>`
  top: 50px;
  right: 0;
  width: 100vw;
  height: 93vh;
  display: ${({ isOpen }) => Boolean(isOpen) ?? false ? 'flex' : 'none'};
  padding: ${get(spacings, 'xxl')}px 0;
  position: absolute;
  box-shadow: 0 3px 6px 0 rgba(51, 102, 204, 0.15);
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${media.lessThan('md')`
    justify-content: space-between;
  `}
`

const MobileLinks = styled.div`
  gap: ${get(spacings, 'l')}px;
  display: flex;
  align-items: space-between;
  flex-direction: column;
  justify-content: center;

  ${media.lessThan('md')`
    align-items: center;
  `}
`

const LinkContainer = styled(Link) <LinkProps>`
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  
  & > p {
    ${({ isCurrent, theme }) => isEqual(isCurrent, true) ? `color: ${get(theme, 'orange', '#FFF')} !important;` : ''};
    ${media.lessThan('md')`
      font-size: 20px;
    `}
  }

  & > svg path, ellipse {
    ${({ isCurrent, theme }) => isEqual(isCurrent, true) ? `stroke: ${get(theme, 'orange', '#FFF')} !important;` : ''};
  }
  
  & > svg rect {
    stroke: ${({ isCurrent, theme }) => `${get(theme, isEqual(isCurrent, true) ? 'orange' : 'blue', '#FFF')} !important;`}
  }
`

const UserLinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: ${get(spacings, 's')}px;
  text-decoration: none;

  &::before {
    height: 100%;
    content: '';
    padding: ${get(spacings, 'xs')}px 0;
    border-left: 1px solid ${({ theme }) => get(theme, 'darkGrey')};
    margin-right: ${get(spacings, 'xs')}px;
  }

  & > svg {
    cursor: pointer;
  }

  ${media.lessThan('md')`
    flex-grow: inherit;
    margin-right: 10px;
    justify-content: center;
  `}
`

const UserNameContainer = styled.div`
  display: flex;

  & p {
    ${media.lessThan('md')`
      font-size: 20px;
    `}
  }
`

const UserPhotoContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-right: ${get(spacings, 'xxs')}px;
  border-radius: 50%;
  justify-content: center;
  background-color: ${({ theme }) => get(theme, 'lightBlue')};

  & > svg {
    margin-right: 0;
  }

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`

const UserSettingsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-right: ${get(spacings, 'xs')}px;
  justify-content: flex-end;
`

const Links = ({ admin }: LinksProps): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <>
      {map(filter(LINKS, link => !isEqual(get(link, 'admin'), true) || isEqual(admin, true)), ({ title, icon, iconOptions, link }: LinkObject): JSX.Element => (
        <LinkContainer to={link} key={`${icon}-${link}`} isCurrent={isEqual(get(location, 'pathname'), link)} iconOptions={iconOptions}>
          <Icon variant={icon} transform='scale(0.9)' stroke />
          <Text color='primary' strong>
            {t<string>(title)}
          </Text>
        </LinkContainer>
      ))}
    </>
  )
}

const UserPhoto = ({ data, isFetching }: UserPartProps): JSX.Element | null => {
  if (isEqual(isFetching, true)) {
    return null
  }

  return (
    <UserPhotoContainer>
      {isNull(get(data, 'photo')) ? <Icon variant={'astronaut'} stroke /> : <img src={get(data, 'photo')} />}
    </UserPhotoContainer>
  )
}

const UserNamePart = ({ data, isFetching }: UserPartProps): JSX.Element | null => {
  if (isEqual(isFetching, true)) {
    return null
  }

  return (
    <UserNameContainer>
      <Text color='primary' strong>
        {get(data, 'name', '')}
      </Text>
    </UserNameContainer>
  )
}

const UserPart = ({ data, isFetching }: UserPartProps): JSX.Element => {
  const setUserToken = useSetRecoilState(userTokenState)

  const onClick = useCallback(() => {
    setUserToken('')
  }, [setUserToken])

  return (
    <UserSettingsContainer>
      <UserLinkContainer to='/settings'>
        <UserPhoto data={data} isFetching={isFetching} />
        <UserNamePart data={data} isFetching={isFetching} />
      </UserLinkContainer>
      <Icon variant='logout' onClick={onClick} stroke />
    </UserSettingsContainer>
  )
}

const RightPart = (): JSX.Element => {
  const [isOpen, updateIsOpen] = useState<boolean>(false)
  const { data, isFetching } = useFetchMeQuery()
  const toggleIsOpen = useCallback(() => updateIsOpen(!isOpen), [isOpen, updateIsOpen])

  return (
    <>
      <DesktopContent>
        <Links admin={isEqual(get(data, 'role'), 'admin')} />
        <UserPart data={data} isFetching={isFetching} />
      </DesktopContent>
      <MobileContent>
        <Icon variant='burger' onClick={toggleIsOpen} stroke />
      </MobileContent>
      <MobileMenu isOpen={isOpen}>
        <MobileLinks>
          <Links admin={isEqual(get(data, 'role'), 'admin')} />
        </MobileLinks>
        <UserPart data={data} isFetching={isFetching} />
      </MobileMenu>
    </>
  )
}

const Navbar = (): JSX.Element => (
  <Container>
    <Link to='/'>
      <Logo />
    </Link>
    <RightPart />
  </Container>
)

export default Navbar
