import styled from 'styled-components'
import { useCallback } from 'react'
import { capitalize, get, isEqual, map, split, join, includes, isNull, isEmpty } from 'lodash'

import Text from '../../atoms/Text'
import numeral from '../../../services/numeral'
import { ProgramSummaryProps } from './ProgramSummary.types'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const Box = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
`

const getLabelByKey = (key: string, ad: any): string => {
  const value = get(ad, key)
  if (isEqual('address', key)) {
    const city = capitalize(get(ad, 'display_city'))
    const zipcode = get(ad, 'zipcode')
    return `${capitalize(value)}, ${city} (${zipcode as string})`
  }

  if (isEqual(key, 'min_price')) {
    const type = get(ad, 'adType')
    const price = numeral(value).format('0.00$')
    return isEqual(type, 'sale') ? price : `${price as string} / mois`
  }

  if (isEqual(key, 'rooms')) {
    return `${value as string} pièce${value > 1 ? 's' : ''}`
  }

  if (isEqual(key, 'floor')) {
    return `${value === 0 ? 'RDC' : `${value as string}e étage`}`
  }

  if (isEqual(key, 'surface')) {
    return `${value as string} m2`
  }

  if (includes(['days', 'extras'], key)) {
    return `${join(split(value, ','), ', ')}`
  }

  if (isEqual(key, 'monthly_charge')) {
    return `${numeral(value).format('0.00$') as string} / mois de charge mensuelle`
  }

  if (isEqual(key, 'property_tax')) {
    const type = get(ad, 'adType')
    return isEqual(type, 'sale') ? `${numeral(value).format('0.00$') as string} de taxe de propriété` : 'Pas de taxe de propriété'
  }

  if (isEqual(key, 'availability')) {
    return isNull(value) ? 'Disponible dès maintenant' : `Disponible le ${new Date(value).toLocaleDateString()}`
  }

  if (isEqual(key, 'user.name')) {
    return `${value as string} ${get(ad, 'user.lastname') as string}`
  }

  if (isEqual(key, 'id_stripe')) {
    return isEqual(value, null) ? '[Annonce non payée]' : `ID STRIPE : ${value as string}`
  }

  return value
}

const AnnonceSummary = ({ ad }: ProgramSummaryProps): JSX.Element => {
  const openUserPage = useCallback(() => {
    const url = `/users/${get(ad, 'user.id', '') as string}`

    const win = window.open(url, '_blank')

    if (win != null) {
      win.focus()
    }
  }, [ad])

  return (
    <Container>
      <Box>
        <Text fontSize={28}>Annonce</Text>
        {map(['name', 'address', 'min_price', 'monthly_charge', 'property_tax', 'availability', 'days', 'profession', 'rooms', 'floor', 'surface', 'extras', 'description'], key => {
          if (isEqual(key, 'days') && !isEqual(get(ad, 'adType'), 'sublet')) {
            return null
          }

          const label = getLabelByKey(key, ad)
          return (
            <p>
              {label}
            </p>)
        })}
      </Box>
      <Box>
        <Text fontSize={28}>Utilisateur</Text>
        {map(['user.name', 'user.email', 'user.phone'], key => {
          const label = getLabelByKey(key, ad)
          return (
            <p onClick={() => isEqual(key, 'user.name') ? openUserPage() : '{}'}>
              {label}
            </p>
          )
        })}
        <Text fontSize={28}>Paiements</Text>
        {map(get(ad, 'payments', []), ({ name, subscription, amount, payment_at: paymentAt }) => {
          const date = new Date(paymentAt)
          const formattedDate = date.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })

          return (
            <p>{isEmpty(subscription) ? '' : '[abonnement] '}{name} payé le {formattedDate} ({amount}e)</p>
          )
        })}
      </Box>
    </Container>
  )
}

export default AnnonceSummary
