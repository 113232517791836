import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './components/pages/Home'
import Users from './components/pages/Users'
import Login from './components/pages/Login'
import Annonce from './components/pages/Annonce'
import Annonces from './components/pages/Annonces'
import AdAlerts from './components/pages/AdAlerts'
import Articles from './components/pages/Articles'
import Settings from './components/pages/Settings'
import Register from './components/pages/Register'
import NotFound from './components/pages/NotFound'
import AuthPage from './components/atoms/AuthPage'
import UserData from './components/pages/UserData'

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/connexion' element={
          <AuthPage loginRequired={false}>
            <Login />
          </AuthPage>
        } />
        <Route path='/inscription' element={
          <AuthPage loginRequired={false}>
            <Register />
          </AuthPage>
        } />
        <Route path='/' element={
          <AuthPage loginRequired={true}>
            <Home />
          </AuthPage>
        } />
        <Route path='/annonces' element={
          <AuthPage loginRequired={true}>
            <Annonces />
          </AuthPage>
        } />
        <Route path='/annonces/:id' element={
          <AuthPage loginRequired={true}>
            <Annonce />
          </AuthPage>
        } />
        <Route path='/articles' element={
          <AuthPage loginRequired={true}>
            <Articles />
          </AuthPage>
        } />
        <Route path='/adalerts' element={
          <AuthPage loginRequired={true}>
            <AdAlerts />
          </AuthPage>
        } />
        <Route path='/users' element={
          <AuthPage loginRequired={true}>
            <Users />
          </AuthPage>
        } />
        <Route path='/users/:id' element={
          <AuthPage loginRequired={true}>
            <UserData />
          </AuthPage>
        } />
        <Route path='/settings' element={
          <AuthPage loginRequired={true}>
            <Settings />
          </AuthPage>
        } />
        <Route path='*' element={
          <AuthPage loginRequired={true}>
            <NotFound />
          </AuthPage>
        } />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
